import { useEffect, useState } from "react"
import { useNecessary } from "../../../hooks/necessary"
import useApi from "../../../services/api/apiHandlerService"
import { upgradeApi } from "../../../types/gameAttributes/booster"
import Coin from "../../images/coin.png"
import Spinner from '../../images/loading.gif'
import { GetIcon } from '../../services/getBoosterIcons'
import UpgradeBar from "../jockeypage/upgradeBar"
interface Props {
  camelIndex: number;
  camelId: string;
}

const UpgradeEl = ({ camelIndex, camelId }: Props) => {
  const [upgrades, setUpgrades] = useState<upgradeApi[][]>([]);
  const [loading, setLoading] = useState<boolean>(true)
  const { identityId,getData} = useNecessary();
  const api = useApi();
  
  const getUpgrade = async () => {
    if (!identityId) return;
    setLoading(true)
    try {
      const res = await api<upgradeApi[][]>({
        method: "GET",
        url: `/boost/upgrade`,
        headers: {
          "x-user-id": identityId,
          "boost-type": "camel",
        },
      });
      if (res) setUpgrades(res);
    } catch (error) {
      console.error("Error fetching upgrades:", error);
    } finally {
      setLoading(false)
    }
  };

  const buyUpgrade = async (boost_id: string) => {
    if (!identityId) return;
    try {
      await api({
        method: "POST",
        url: `/boost/buy-camel-boost?boost_id=${boost_id}&camel_id=${camelId}`,
        headers: {
          "x-user-id": identityId,
        },
      });
      getData()
      getUpgrade();
    } catch (error) {
      console.error("Error buying upgrade:", error);
    }
  };

  useEffect(() => {
    if (identityId) {
      getUpgrade();
    }
  }, [identityId]);

  return (
    <div className="flex gap-2 flex-col">
      {loading ? (
        <div className='flex justify-center'>
        <img src={Spinner} className='w-[35px] h-[35px]' alt="" />
        </div>
      ) : (
        upgrades.length > 0 ? (
          upgrades.map((upgradeArray, index) =>
            camelIndex === index ? (
              upgradeArray.map((upgrade) => (
                <div
                  key={upgrade.boost_id}
                  className="bg-ColorsChapter2-150 border border-ColorsChapter2-200 custom-sm:py-1 flex p-2 rounded-2xl gap-3"
                >
                  <div>
                    <GetIcon icon={upgrade?.type}/>
                  </div>
                  <div className="w-full">
                    <div className="flex gap-1 flex-col">
                      <p className="text-base font-semibold">{upgrade.type}</p>
                      <p className="text-sm font-medium text-colorsText-50 max-w-48">
                        {upgrade?.description}
                      </p>
                    </div>
                    <div className="flex gap-1 justify-between items-center mt-2">
                      <UpgradeBar lvl={upgrade.lvl} index={camelIndex} />
                      {upgrade.lvl === 10 ? (
                        <button className="flex max-w-20 w-full text-white items-center px-3 py-1.5 bg-ColorsChapter2-200 rounded-md justify-center opacity-55">
                          Max
                        </button>
                      ) : (
                        <button
                          onClick={() => buyUpgrade(upgrade.boost_id)}
                          className="flex max-w-20 w-full text-black items-center px-3 py-1.5 bg-ColorsChapter2-50 rounded-md"
                        >
                          <img className="w-5 h-5" src={Coin} alt="Монетка" />
                          {upgrade.price}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                key={index}
                className="w-full text-center mt-4 p-5 text-sm rounded-xl"
                style={{
                  background: `#181819`,
                  border: "1px solid #28282A",
                  color: `#6C717D`,
                }}
              >
                You dont own this camel
              </div>
            )
          )
        ) : (
          <p className="text-center text-sm text-gray-500">
            No upgrades available
          </p>
        )
      )}
    </div>
  );
};

export default UpgradeEl;
