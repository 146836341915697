// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressbar {
	position: relative;
	width: 100%;
}

.progress_bar {
	width: 100%;
	height: 15px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 55px;
	margin-top: 7px;
	overflow: hidden;
	position: relative;
}

.progress_bar__inner {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: #A67C50;
	background-image: linear-gradient(
			-45deg,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
	);
	background-size: 30px 30px;
	border-radius: 55px;
	animation: progress-bar-stripes 2s linear infinite;
	color: white;
	font-weight: bold;
	font-size: 14px;
	transition: 0.1s ease;
}

@keyframes progress-bar-stripes {
	0% {
			background-position: 0 0;
	}
	100% {
			background-position: 30px 30px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/taskpage/progress-module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,0CAA0C;CAC1C,mBAAmB;CACnB,eAAe;CACf,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,YAAY;CACZ,yBAAyB;CACzB;;;;;;;;;EASC;CACD,0BAA0B;CAC1B,mBAAmB;CACnB,kDAAkD;CAClD,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,qBAAqB;AACtB;;AAEA;CACC;GACE,wBAAwB;CAC1B;CACA;GACE,8BAA8B;CAChC;AACD","sourcesContent":[".progressbar {\n\tposition: relative;\n\twidth: 100%;\n}\n\n.progress_bar {\n\twidth: 100%;\n\theight: 15px;\n\tborder: 1px solid rgba(255, 255, 255, 0.2);\n\tborder-radius: 55px;\n\tmargin-top: 7px;\n\toverflow: hidden;\n\tposition: relative;\n}\n\n.progress_bar__inner {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 100%;\n\tbackground-color: #A67C50;\n\tbackground-image: linear-gradient(\n\t\t\t-45deg,\n\t\t\trgba(255, 255, 255, 0.15) 25%,\n\t\t\ttransparent 25%,\n\t\t\ttransparent 50%,\n\t\t\trgba(255, 255, 255, 0.15) 50%,\n\t\t\trgba(255, 255, 255, 0.15) 75%,\n\t\t\ttransparent 75%,\n\t\t\ttransparent\n\t);\n\tbackground-size: 30px 30px;\n\tborder-radius: 55px;\n\tanimation: progress-bar-stripes 2s linear infinite;\n\tcolor: white;\n\tfont-weight: bold;\n\tfont-size: 14px;\n\ttransition: 0.1s ease;\n}\n\n@keyframes progress-bar-stripes {\n\t0% {\n\t\t\tbackground-position: 0 0;\n\t}\n\t100% {\n\t\t\tbackground-position: 30px 30px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
