type props = {
  color: string;
};
const FriendIcon = ({ color }: props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_197_2580)">
        <path
          d="M7.75337 13.1474C7.46627 13.3013 7.18491 13.4624 6.97814 13.5903C6.48354 13.8795 5.55191 14.4241 4.87889 15.1082C4.44456 15.5497 3.8736 16.2837 3.76733 17.2933C3.73451 17.6051 3.74934 17.9036 3.80304 18.1871C3.85678 18.4709 3.88366 18.6128 3.81186 18.6872C3.74006 18.7616 3.62042 18.7434 3.38114 18.707C2.35732 18.5512 1.66543 17.9931 1.09764 17.3845C0.673078 16.9295 0.453787 16.4061 0.508175 15.8464C0.55924 15.3208 0.84019 14.8976 1.12118 14.5882C1.56466 14.0998 2.21834 13.686 2.60981 13.4381C2.69345 13.3852 2.76514 13.3398 2.82023 13.3029C4.29765 12.3132 6.0597 12.0212 7.70505 12.427C8.09296 12.5226 8.28691 12.5704 8.30406 12.7068C8.3212 12.8431 8.13193 12.9445 7.75337 13.1474Z"
          fill={color}
        />
        <path
          d="M6.73741 5.28631C6.92712 5.31484 7.02197 5.3291 7.0707 5.4079C7.11944 5.48671 7.08547 5.59136 7.01754 5.80068C6.84386 6.33583 6.75 6.90695 6.75 7.5C6.75 8.73995 7.16032 9.88407 7.85259 10.804C7.98465 10.9795 8.05068 11.0672 8.03033 11.1575C8.00999 11.2478 7.92509 11.2922 7.7553 11.3811C7.30526 11.6167 6.7932 11.75 6.25 11.75C4.45507 11.75 3 10.2949 3 8.5C3 6.70507 4.45507 5.25 6.25 5.25C6.41566 5.25 6.57842 5.26239 6.73741 5.28631Z"
          fill={color}
        />
        <path
          d="M16.7471 13.1474C17.0342 13.3013 17.3155 13.4624 17.5223 13.5903C18.0169 13.8795 18.9485 14.4241 19.6215 15.1082C20.0559 15.5497 20.6268 16.2837 20.7331 17.2933C20.7659 17.6051 20.7511 17.9036 20.6974 18.1871C20.6437 18.4709 20.6168 18.6128 20.6886 18.6872C20.7604 18.7616 20.88 18.7434 21.1193 18.707C22.1431 18.5512 22.835 17.9931 23.4028 17.3845C23.8274 16.9295 24.0467 16.4061 23.9923 15.8464C23.9412 15.3208 23.6602 14.8976 23.3793 14.5882C22.9358 14.0998 22.2821 13.686 21.8906 13.4381C21.807 13.3852 21.7353 13.3398 21.6802 13.3029C20.2028 12.3132 18.4407 12.0212 16.7954 12.427C16.4075 12.5226 16.2135 12.5704 16.1964 12.7068C16.1792 12.8431 16.3685 12.9445 16.7471 13.1474Z"
          fill={color}
        />
        <path
          d="M16.6478 10.804C16.5157 10.9795 16.4497 11.0672 16.47 11.1575C16.4904 11.2478 16.5753 11.2922 16.745 11.3811C17.1951 11.6167 17.7072 11.75 18.2503 11.75C20.0453 11.75 21.5003 10.2949 21.5003 8.5C21.5003 6.70507 20.0453 5.25 18.2503 5.25C18.0847 5.25 17.9219 5.26239 17.7629 5.28631C17.5732 5.31484 17.4784 5.3291 17.4296 5.4079C17.3809 5.48671 17.4149 5.59136 17.4828 5.80068C17.6565 6.33583 17.7503 6.90695 17.7503 7.5C17.7503 8.73995 17.34 9.88407 16.6478 10.804Z"
          fill={color}
        />
        <path
          d="M7.94146 14.4733C10.5792 12.8422 13.925 12.8422 16.5627 14.4733C16.6405 14.5214 16.739 14.579 16.8522 14.6452C17.3645 14.945 18.1776 15.4208 18.7325 15.9849C19.0811 16.3391 19.4287 16.8221 19.492 17.4242C19.5599 18.0683 19.2864 18.6646 18.7649 19.1806C17.9033 20.033 16.8359 20.75 15.4365 20.75H9.06773C7.66827 20.75 6.60094 20.033 5.73932 19.1806C5.21775 18.6646 4.94435 18.0683 5.01215 17.4242C5.07553 16.8221 5.42313 16.3391 5.77165 15.9849C6.32655 15.4208 7.13974 14.945 7.65201 14.6452C7.76518 14.579 7.8637 14.5214 7.94146 14.4733Z"
          fill={color}
        />
        <path
          d="M8.0018 7.5C8.0018 5.15279 9.90459 3.25 12.2518 3.25C14.599 3.25 16.5018 5.15279 16.5018 7.5C16.5018 9.84721 14.599 11.75 12.2518 11.75C9.90459 11.75 8.0018 9.84721 8.0018 7.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_197_2580">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FriendIcon;
