import { Player } from '@remotion/player'
import { useEffect, useState } from 'react'
import CamelSkinLoader from './camelSkinLoader'

const Camel: React.FC = () => {
  interface Sizes {
    width: number;
    height: number;
  }

  const [compositionSize, setCompositionSize] = useState<Sizes>({
    width: 340,
    height: 332,
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 375) {
        setCompositionSize({
          width: 285,
          height: 320,
        });
      } else if (screenWidth > 375 && screenWidth <= 428) {
        setCompositionSize({
          width: 300,
          height: 350,
        });
      } else {
        setCompositionSize({
          width: 350,
          height: 320,
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '75%',
        left: '60%',
        transform: `translate(-50%, -50%)`,
        width: '100%',
      }}
    >
      <Player
        component={CamelSkinLoader}
        durationInFrames={300}
        fps={60}
        compositionWidth={compositionSize.width}
        compositionHeight={compositionSize.height}
        loop
        autoPlay
        style={{
          width: `${compositionSize.width}px`,
          height: `${compositionSize.height}px`,
        }}
      />
    </div>
  );
};

export default Camel;
