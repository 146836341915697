import useStore from "../../components/store/zustand";
import useApi from "../../services/api/apiHandlerService";
import { signInResult } from "../../types/identity/signInResult";
import { signUpResult } from "../../types/identity/signUpResult";

export const useIdentityHook = () => {
  const api = useApi();
  const { updateAvatar, updateUsername, updateIdentityId } = useStore();

  const signInAsync = async (user_id: number) => {
    const res = await api<signInResult>({
      method: "POST",
      url: `/auth/sign-in?user_id=${user_id}`,
    });
    if (res) {
      setIdentity(res);
    }
  };

  const signUpAsync = async (
    user_id: number | null,
    username: string,
    file: File | null
  ) => {
    const res = await api<signUpResult>({
      method: "POST",
      url: `/auth/sign-up`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        user_id: user_id,
        username: username,
        file: file,
      },
    });

    return res;
  };

  const isUserExistAsync = async (user_id: number | null) => {
    const res = await api<boolean>({
      method: "GET",
      url: `/auth/user-exist?user_id=${user_id}`,
    });

    return res;
  };

  const isNicknameAvailableAsync = async (username: string) => {
    const res = await api<boolean>({
      method: "GET",
      url: `/auth/nickname-valid?username=${username}`,
    });

    return res;
  };

  const setIdentity = (res: signInResult) => {
    updateIdentityId(res.user_id);
    updateAvatar(res.avatar);
    updateUsername(res.username);
  };

  return {
    signInAsync,
    signUpAsync,
    isUserExistAsync,
    isNicknameAvailableAsync,
  };
};
