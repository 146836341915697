import { useEffect, useState } from "react"
import { useNecessary } from "../../../hooks/necessary"
import useApi from "../../../services/api/apiHandlerService"
import { upgradeApi } from "../../../types/gameAttributes/booster"
import Coin from "../../images/coin.png"
import Spinner from '../../images/loading.gif'
import { GetIcon } from '../../services/getBoosterIcons'
import UpgradeBar from "./upgradeBar"
interface Props {
  boosterIndex: number;
  jockeyId: string;
}

const UpgradeEl = ({ boosterIndex, jockeyId }: Props) => {
  const [upgrades, setUpgrades] = useState<upgradeApi[][]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { identityId, getData } = useNecessary();
  const api = useApi();

  const getUpgrade = async () => {
    if (!identityId) return;
    setIsLoading(true);
    try {
      const res = await api<upgradeApi[][]>({
        method: "GET",
        url: `/boost/upgrade`,
        headers: {
          "x-user-id": identityId,
          "boost-type": "jokey",
        },
      });
      if (res) setUpgrades(res);
    } catch (error) {
      console.error("Error fetching upgrades:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const buyUpgrade = async (boost_id: string) => {
    if (!identityId) return;
    try {
      await api({
        method: "POST",
        url: `/boost/buy-jokey-boost?boost_id=${boost_id}&jokey_id=${jockeyId}`,
        headers: {
          "x-user-id": identityId,
        },
      });
      getData()
      getUpgrade();
    } catch (error) {
      console.error("Error buying upgrade:", error);
    }
  };

  useEffect(() => {
    if (identityId) {
      getUpgrade();
    }
  }, [identityId]);

  return (
    <div className="flex gap-2 flex-col">
      {isLoading ? (
        <div className="flex justify-center">
          <img src={Spinner}className='h-[35px] w-[35px]' alt="" />
        </div>
      ) : (
        upgrades[boosterIndex] && upgrades[boosterIndex].length > 0 ? (
          upgrades[boosterIndex].map((upgrade) => (
            <div
              key={upgrade?.boost_id}
              className="bg-ColorsChapter2-150 border border-ColorsChapter2-200 custom-sm:py-1 flex p-2 rounded-2xl gap-3"
            >
              <div>
                <GetIcon icon={upgrade?.type}/>
              </div>
              <div className="w-full">
                <div className="flex gap-1 flex-col">
                  <p className="text-base font-semibold">{upgrade.type}</p>
                  <p className="text-sm font-medium text-colorsText-50 max-w-48">
                    {upgrade?.description}
                  </p>
                </div>
                <div className="flex gap-1 justify-between items-center mt-2">
                  <UpgradeBar lvl={upgrade.lvl} index={boosterIndex} />
                  {upgrade.lvl === 10 ? (
                    <button className="flex max-w-20 w-full text-white items-center px-3 py-1.5 bg-ColorsChapter2-200 rounded-md justify-center opacity-55">
                      Max
                    </button>
                  ) : (
                    <button
                      onClick={() => buyUpgrade(upgrade.boost_id)}
                      className="flex max-w-20 w-full text-black items-center px-3 py-1.5 bg-ColorsChapter2-50 rounded-md"
                    >
                      <img className="w-5 h-5" src={Coin} alt="Coin" />
                      {upgrade.price}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full text-center mt-4 p-5 text-sm rounded-xl" style={{ background: `#181819`, border: '1px solid #28282A', color: `#6C717D` }}>
            You dont own this jockey.
          </div>
        )
      )}
    </div>
  );
};

export default UpgradeEl;
