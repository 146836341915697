import { useEffect, useState } from 'react'
import { useNecessary } from '../../../hooks/necessary'
import useApi from '../../../services/api/apiHandlerService'
import coinIcon from '../../images/coin.png'
import { ReactComponent as TaskCompleteIcon } from '../../images/taskpage/taskcompleted.svg'
import useStore from '../../store/zustand'
import './progress-module.css'
type Props = {
	completed: number
	lenghtOfmission:number
	reward: number,
	claimed: boolean | null
	getMissions: () => void
}

const TaskCompleted:React.FC<Props> = ({completed, lenghtOfmission, reward, claimed, getMissions}) => {
	
	const api = useApi()
	const {identityId} = useStore()
	const [isReady, setIsReady] = useState(false)
	const progress = (completed / lenghtOfmission) * 100;
	const {getData} = useNecessary()

  useEffect(() => {
		if (claimed === true || claimed === false) {
			setIsReady(true)
		}
  }, [claimed])

	const claimRewardAsync = async(): Promise<void> => {
    await api({
      method: 'POST',
      url: '/mission/claim-reward-tasks',
      headers: {
        "x-user-id": identityId
      }
    });
		getMissions()
		getData()
  }

	const validateTheButton = () => {

		if (claimed === true || completed !== lenghtOfmission || !isReady) {
			return (
			<button className="bg-ColorsChapter2-150 text-white px-4 py-2 opacity-35 rounded-lg mt-3 w-full font-bold text-sm h-[50px] flex justify-center gap-1 items-center">
						<img src={coinIcon} className='w-[30px] h-[30px]' alt="" />
						<p>+{reward} Points</p>
			</button>
			)
		} else {
			return (
				<button className="bg-ColorsChapter2-50 text-black px-4 py-2 rounded-lg mt-3 w-full font-bold text-sm h-[50px] flex justify-center gap-1 items-center" onClick={() => claimRewardAsync()}>
						<img src={coinIcon} className='w-[30px] h-[30px]' alt="" />
						<p>+{reward} Points</p>
			  </button>
			)
		}
	}

	return (
		<div className='w-full px-2 py-2 pt-3 rounded-xl bg-ColorsChapter2-150 text-white relative border-ColorsChapter2-200 border-[2px]'>
      <div className='flex gap-1 items-center'>
				<div className='rounded-full border border-ColorsChapter2-200'>
        <TaskCompleteIcon/>
				</div>
        <p className='text-[15px] text-ColorsChapter2-500'>Complete task to earn more points
				and invitations</p>
      </div>

      <div className='mt-4 p-2 border border-ColorsChapter2-400 rounded-xl' style={{background: 'linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%'
      }}>
					<div className='w-full flex justify-between items-center'>
          <p className='text-sm text-white'>
            Completed 
          </p>
					<span className='text-sm text-white'>{completed} <span className='text-ColorsChapter2-100'>/</span> <span className='text-ColorsChapter2-550'>{lenghtOfmission}</span></span>
					</div>

					<div className="progressbar">
							<div className="progress_bar">
									<div className="progress_bar__inner" style={{ width: `${progress}%` }}/>
							</div>
					</div>
          
					{validateTheButton()}
        </div>
    </div>
	)
}

export default TaskCompleted