import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "../components/ui/background.css"
import NicknameInput from "../components/ui/registrationpage/nicknameInput"
import { useIdentityHook } from "../hooks/identities/identity"
import { useTelegram } from "../hooks/useTelegram"

const RegistrationPage = () => {
  const { userId } = useTelegram();
  const [step, setStep] = useState(0);
  const [nickname, setNickname] = useState<string>("");
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [nicknameState, setNicknameState] = useState<boolean | null>(null);
  const { signUpAsync, signInAsync } = useIdentityHook();
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsKeyboardVisible(
        window.innerHeight < document.documentElement.clientHeight
      );
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNextStep = () => {
    if (step < 1) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const signUp = async () => {
    await signUpAsync(userId, nickname, file);
    signInAsync(userId);
    navigate("/");
  };

  return (
    <>
      <div className="background opacity-10" />
      <div className="w-full flex flex-col justify-center items-center">
        <div className="max-w-[350px] w-full justify-center items-center">
          <div className="mt-[50%] flex flex-col gap-10">
            <p className="text-white text-xl text-center">
              Choose your nickname
            </p>
            <NicknameInput
              setNickname={setNickname}
              nickname={nickname}
              setNicknameState={setNicknameState}
            />
          </div>
          {nicknameState === false && nickname.length > 0 ? (
            <div
              className={`fixed w-full bottom-0 left-0 flex justify-center mb-12 ${
                isKeyboardVisible ? "hidden" : ""
              }`}
            >
              <button
                onClick={signUp}
                className="bg-ColorsChapter2-50 text-black px-4 py-2 rounded-lg font-medium mx-5 w-full"
              >
                Continue
              </button>
            </div>
          ) : (
            <div
              className={`fixed w-full bottom-0 left-0 flex justify-center mb-12 ${
                isKeyboardVisible ? "hidden" : ""
              }`}
            >
              <button className="bg-ColorsChapter2-50 text-black px-4 py-2 rounded-lg font-medium mx-5 w-full opacity-55">
                Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RegistrationPage;
