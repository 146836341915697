import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { abbreviateNumber } from '../../../hooks/convertNumber'
import { useNecessary } from "../../../hooks/necessary"
import useApi from "../../../services/api/apiHandlerService"
import { camel } from "../../../types/gameAttributes/camel"
import Coin from '../../images/coin.png'
import Spinner from '../../images/loading.gif'
import SelectCamel from "./selectCamel"
import UpgradeCamelEl from "./upgradeCamelEl"
const CamelShoppage = () => {
  const api = useApi();
  const [camels, setCamels] = useState<camel[]>([]);
  const { identityId, getData } = useNecessary();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [camelIndex, setCamelIndex] = useState<number>(0);
  const [camelId, setCamelId] = useState<string>("");
  const navigate = useNavigate();

  const getCamels = async () => {
    if (!identityId) return;
    setLoading(true);
    try {
      const res = await api<camel[]>({
        method: "GET",
        url: `/game/get-camels`,
        headers: {
          "x-user-id": identityId,
        },
      });
      if (res) setCamels(res);
    } catch (error) {
      console.error("Error fetching camels:", error);
    } finally {
      setLoading(false);
    }
  };

  const buyCamel = async (idCamel: string) => {
    if (!identityId) return;
    setIsLoading(true);
    try {
      await api({
        url: `/game/camel/buy?camel_id=${idCamel}`,
        method: 'POST',
        headers: {
          'x-user-id': identityId,
        },
      });
      getData();
      getCamels();
    } catch (error) {
      console.error("Error buying camel:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCamels();
  }, [identityId]);

  useEffect(() => {
    if (camels.length > 0) {
      setCamelId(camels[camelIndex]?.camel_id || "");
    }
  }, [camelIndex, camels]);

  return (
    <div className="flex justify-center text-white">
      <div className="max-w-[350px] w-full">
        {loading ? (
          <div className='flex justify-center h-screen'>
          <img src={Spinner} className='w-[35px] h-[35px]' alt="" />
          </div>
        ) : (
          <div className="relative">
            <div
              className="bg-ColorsChapter2-200 p-[2px] rounded-2xl mb-4"
              style={{
                clipPath: "polygon(100% 0, 100% 31%, 100% 83%, 68% 83%, 52% 100%, 0 100%, 0 0)",
              }}
            >
              <div
                className="w-full rounded-xl h-[285px] bg-ColorsChapter2-450"
                style={{
                  clipPath: "polygon(100% 0, 100% 31%, 100% 83%, 68% 83%, 52% 100%, 0 100%, 0 0)",
                }}
              >
                <div className="flex w-full pt-1 gap-1 px-5 z-[999] relative" />
                <SelectCamel
                  camels={camels}
                  setCamelIndex={setCamelIndex}
                  camelIndex={camelIndex}
                />
              </div>
            </div>
            <UpgradeCamelEl camelIndex={camelIndex} camelId={camelId} />
          </div>
        )}
        {camels[camelIndex]?.lock ? (
          isLoading ? (
            <button className="w-full p-2 mt-2 rounded flex justify-center items-center gap-1" style={{ background: `#FBDAAF` }}>
              <p className="text-black font-medium text-base">Processing...</p>
            </button>
          ) : (
            <button
              className="bg-ColorsChapter2-50 w-full p-2 mt-2 rounded flex justify-center items-center gap-1"
              onClick={() => buyCamel(camels[camelIndex]?.camel_id)}
            >
              <img src={Coin} alt="" className="w-[25px] h-[25px]" />
              <p className="text-black font-medium text-base">
                {abbreviateNumber(camels[camelIndex]?.price)}
              </p>
            </button>
          )
        ) : (
          <button
            className={`bg-myColor-600 w-full p-2 mt-2 rounded flex justify-center items-center ${!camelId ? 'opacity-55 cursor-not-allowed' : ''}`}
            disabled={!camelId}
            onClick={() => navigate('/game', { state: { camelId } })}
          >
            <p className="text-black font-medium text-base">Start</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default CamelShoppage;
