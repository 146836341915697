import { useEffect, useState } from 'react'
import { missionsInterface } from '../components/models'
import useStore from '../components/store/zustand'
import TaskCompleted from '../components/ui/taskpage/taskCompleted'
import TaskTable from '../components/ui/taskpage/taskTable'
import useApi from '../services/api/apiHandlerService'

const TasksPage = () => {
	const [completed, setCompleted] = useState<number>(0)
	const [lenghtOfmission, setLenghtOfmission] = useState<number>(0)
	const [claimed, setClaimed] = useState<boolean | null>(null)
	const [reward, setReward] = useState<number>(0)
  const [missions, setMissions] = useState<missionsInterface>();
  const [loading, setLoading] = useState<boolean>(false);
	const {identityId} = useStore()
	const [firstRender, setFirstRender] = useState(true)
	const [isMobile, setIsMobile] = useState<boolean | null>(false);

	const api = useApi()

	useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerHeight < 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

	const getMissions = async (): Promise<void> => {
    if (!identityId) return;
		if (firstRender) {
			setLoading(true);
		}
    const res = await api<missionsInterface>({
      method: 'GET',
      url: '/mission/',
      headers: {
        "x-user-id": identityId
      }
    });
    setLoading(false);
		setFirstRender(false)
    if (res) {
      setMissions(res);
      setCompleted(res?.completed);
      setLenghtOfmission(res?.data.length);
      setReward(res?.reward);
      setClaimed(res?.claimed);
    }
  };

	return (
		<>
			<div className='h-full overflow-y-scroll'>
				<div className='flex justify-center'>
					<div className='max-w-[350px] w-full text-white'>
					<p className='text-3xl text-center font-bold'>
				  Tasks
			    </p>
					<p className='text-sm text-center text-ColorsChapter2-500'>Complete Tasks to Maximize Your Earnings</p>

					{isMobile ? (
        <>
          <div className='mt-10 h-pc:px-2'>
            <TaskTable
              setCompleted={setCompleted}
              setLenghtOfmission={setLenghtOfmission}
              setReward={setReward}
              setClaimed={setClaimed}
              getMissions={getMissions}
              loading={loading}
              missions={missions}
            />
          </div>
          <div className='mt-5 h-pc:px-2'>
            <TaskCompleted
              completed={completed}
              lenghtOfmission={lenghtOfmission}
              claimed={claimed}
              reward={reward}
              getMissions={getMissions}
            />
          </div>
        </>
      ) : (
        <>
          <div className='mt-5'>
            <TaskCompleted
              completed={completed}
              lenghtOfmission={lenghtOfmission}
              claimed={claimed}
              reward={reward}
              getMissions={getMissions}
            />
          </div>
          <div className='mt-10'>
            <TaskTable
              setCompleted={setCompleted}
              setLenghtOfmission={setLenghtOfmission}
              setReward={setReward}
              setClaimed={setClaimed}
              getMissions={getMissions}
              loading={loading}
              missions={missions}
            />
          </div>
        </>
      )}
							
							

					</div>
				</div>
			</div>
		</>
	)
}

export default TasksPage
