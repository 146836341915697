/* import { initUtils } from '@tma.js/sdk' */
import { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import JockeyIcon from "../images/iconsNavpanel/jockey";
import FriendIcon from "../images/iconsNavpanel/friends";
import HomeIcon from "../images/iconsNavpanel/home";
import TasksIcon from "../images/iconsNavpanel/tasks";
import CamelIcon from "../images/iconsNavpanel/camel";
const NavPanel = () => {
  const [activeLink, setActiveLink] = useState<string | null | boolean>(null);
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <footer className="fixed bottom-0 left-0 w-full text-white bg-ColorsChapter2-450 border-t z-[999] border-ColorsChapter2-200">
        <div className="w-full h-[65px] flex items-center justify-around rounded-t-2xl">
          <Link
            to={"/"}
            className="flex flex-col items-center w-[80px] rounded-b-xl"
            style={
              activeLink === "/"
                ? {
                    background:
                      "linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%)",
                  }
                : {}
            }
          >
            <HomeIcon color={activeLink === "/" ? "#F8C17A" : "#56565A"} />
            <p
              className="text-[10px] text-center  tracking-wider"
              style={{ color: activeLink === "/" ? "#F8C17A" : "#56565A" }}
            >
              Home
            </p>
          </Link>

          <Link
            to={"/camel"}
            className="flex flex-col items-center w-[80px] rounded-b-xl"
            style={
              activeLink === "/camel"
                ? {
                    background:
                      "linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%)",
                  }
                : {}
            }
          >
            <CamelIcon
              color={activeLink === "/camel" ? "#F8C17A" : "#56565A"}
            />
            <p
              className="text-[10px] text-center  tracking-wider"
              style={{ color: activeLink === "/camel" ? "#F8C17A" : "#56565A" }}
            >
              Camel
            </p>
          </Link>

          <Link
            to={"/jockey"}
            className="flex flex-col items-center w-[80px] rounded-b-xl"
            style={
              activeLink === "/jockey"
                ? {
                    background:
                      "linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%)",
                  }
                : {}
            }
          >
            <JockeyIcon
              color={activeLink === "/jockey" ? "#F8C17A" : "#56565A"}
            />
            <p
              className="text-[10px] text-center  tracking-wider"
              style={{
                color: activeLink === "/jockey" ? "#F8C17A" : "#56565A",
              }}
            >
              Jockey
            </p>
          </Link>

          <Link
            to={"/tasks"}
            className="flex flex-col items-center w-[80px] rounded-b-xl"
            style={
              activeLink === "/tasks"
                ? {
                    background:
                      "linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%)",
                  }
                : {}
            }
          >
            <TasksIcon
              color={activeLink === "/tasks" ? "#F8C17A" : "#56565A"}
            />
            <p
              className="text-[10px] text-center  tracking-wider"
              style={{ color: activeLink === "/tasks" ? "#F8C17A" : "#56565A" }}
            >
              Tasks
            </p>
          </Link>

          <Link
            to={"/friends"}
            className="flex flex-col items-center w-[80px] rounded-b-xl"
            style={
              activeLink === "/friends"
                ? {
                    background:
                      "linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%)",
                  }
                : {}
            }
          >
            <FriendIcon
              color={activeLink === "/friends" ? "#F8C17A" : "#56565A"}
            />
            <p
              className="text-[10px] text-center tracking-wider"
              style={{
                color: activeLink === "/friends" ? "#F8C17A" : "#56565A",
              }}
            >
              Friends
            </p>
          </Link>
        </div>
      </footer>
      {location.pathname !== "/game" && <div className="pb-[70px]" />}
    </>
  );
};

export default memo(NavPanel);
