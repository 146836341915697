import { Player } from '@remotion/player'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNecessary } from '../../../hooks/necessary'

const isMacOS = navigator.userAgent.includes('Macintosh');
const TARGET_FPS = isMacOS ? 70 : 45;
const FRAME_DURATION = 1000 / TARGET_FPS; // 16.67 ms for 60fps

const YourComponent: React.FC = () => {
  const [frame, setFrame] = useState<number>(0);
  const { backgroundImages, isActive } = useNecessary();
  const lastFrameTimeRef = useRef<number>(0); // To track the last frame update time

  useEffect(() => {
    if (isActive && backgroundImages.length > 0) {
      let animationFrameId: number;
      let lastTime = performance.now(); 
  
      const animate = (time: number) => {
        const timeSinceLastFrame = time - lastTime;
  
        if (timeSinceLastFrame >= FRAME_DURATION) {
          setFrame((prevFrame) => (prevFrame + 1) % backgroundImages.length);
          lastTime = time - (timeSinceLastFrame % FRAME_DURATION);
        }
  
        animationFrameId = requestAnimationFrame(animate);
      };
  
      animationFrameId = requestAnimationFrame(animate);
  
      return () => cancelAnimationFrame(animationFrameId);
    }
  }, [backgroundImages, isActive]);

  const imageUrl = useMemo(() => backgroundImages[frame]?.src, [frame, backgroundImages]);

  return (
    <img
      src={imageUrl}
      alt="Frame"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        objectFit: 'cover',
        zIndex: 99,
      }}
    />
  );
};

const BackgroundGame: React.FC = () => {
  interface sizes {
    width: number;
    height: number;
  }
  const [compositionSize, setCompositionSize] = useState<sizes>({
    width: 375,
    height: 667,
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 375) {
        setCompositionSize({
          width: 375,
          height: 667,
        });
      } else if (screenWidth <= 390) {
        setCompositionSize({
          width: 390,
          height: 844,
        });
      } else if (screenWidth <= 414) {
        setCompositionSize({
          width: 414,
          height: 836,
        });
      } else {
        setCompositionSize({
          width: 430,
          height: 932,
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        left: 0,
        backgroundColor: '#000',
      }}
    >
      <Player
        component={YourComponent}
        durationInFrames={61}
        compositionWidth={1920}
        compositionHeight={1080}
        fps={TARGET_FPS}
        loop
        autoPlay
      />
    </div>
  );
};

export default BackgroundGame;
