import HowToPlay from '../components/ui/howToPlay'


const HowToPlaypage = () => {
  
	return (
		<>
		<div className='background opacity-10'/>
          <div className="fixed inset-0 flex items-center justify-center z-50">
             <div className='w-full flex justify-center items-center'>
                <div className='max-w-[350px] w-full justify-center items-center'>
                  <div className='mt-10'>
                    <HowToPlay/>
                  </div>
                </div>
           </div>
          </div>
		</>
	)
}

export default HowToPlaypage