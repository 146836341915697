import { useEffect, useState } from "react"
import { abbreviateNumber } from '../../../hooks/convertNumber'
import { useNecessary } from "../../../hooks/necessary"
import useApi from "../../../services/api/apiHandlerService"
import { booster } from "../../../types/gameAttributes/booster"
import Coin from '../../images/coin.png'
import Spinner from '../../images/loading.gif'
import SelectBooster from "./selectBooster"
import UpgradeEl from "./upgradeEl"
const JockeyShoppage = () => {
  const api = useApi();
  const [boosters, setBoosters] = useState<booster[]>([]);
  const { identityId, getData } = useNecessary();
  const [loading, setLoading] = useState<boolean>(true);
  const [boosterIndex, setBoosterIndex] = useState<number>(0);
  const [loadingBooster, setLoadingBooster] = useState<boolean>(false);
  const [jockeyId, setJockeyId] = useState<string>("");
  const buyBoost = async (idBooster: string) => {
    setLoadingBooster(true);
    try {
      await api({
        url: `/game/booster/buy?booster_id=${idBooster}`,
        method: 'POST',
        headers: { 'x-user-id': identityId },
      });
      getData();
      getBoosters();
    } catch (error) {
      console.error("Error buying booster:", error);
    } finally {
      setLoadingBooster(false);
    }
  };

  const selectBooster = async (idBooster: string) => {
    try {
      await api({
        url: `/game/booster/select?booster_id=${idBooster}`,
        method: 'POST',
        headers: { 'x-user-id': identityId },
      });
      getBoosters();
    } catch (error) {
      console.error("Error selecting booster:", error);
    }
  };

  const getBoosters = async () => {
    if (!identityId) return;
    setLoading(true);
    try {
      const res = await api<booster[]>({
        method: "GET",
        url: `/game/get-boosters`,
        headers: { "x-user-id": identityId },
      });
      if (res) setBoosters(res);
    } catch (error) {
      console.error("Error fetching boosters:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBoosters();
  }, [identityId]);

  useEffect(() => {
    if (boosters[boosterIndex]) {
      setJockeyId(boosters[boosterIndex].booster_id || "");
    }
  }, [boosterIndex, boosters]);

  return (
    <div className="flex justify-center text-white">
      <div className="max-w-[350px] w-full">
        {loading ? (
          <div className="flex justify-center h-screen">
            <img src={Spinner} className='w-[35px] h-[35px]' alt="" />
          </div>
        ) : (
          <div className="relative">
            <div
              className="bg-ColorsChapter2-200 p-[2px] rounded-2xl mb-4"
              style={{
                clipPath: "polygon(100% 0, 100% 31%, 100% 83%, 68% 83%, 52% 100%, 0 100%, 0 0)",
              }}
            >
              <div
                className="w-full rounded-xl h-[285px] bg-ColorsChapter2-450"
                style={{
                  clipPath: "polygon(100% 0, 100% 31%, 100% 83%, 68% 83%, 52% 100%, 0 100%, 0 0)",
                }}
              >
                <div className="flex w-full pt-1 gap-1 px-5 z-[999] relative"></div>
                <SelectBooster
                  boosters={boosters}
                  setBoosterIndex={setBoosterIndex}
                  boosterIndex={boosterIndex}
                />
              </div>
            </div>
            <UpgradeEl boosterIndex={boosterIndex} jockeyId={jockeyId} />
          </div>
        )}
        {boosters[boosterIndex]?.lock ? (
          loadingBooster ? (
            <button
              className="w-full p-2 mt-2 rounded flex justify-center items-center gap-1"
              style={{ background: `#FBDAAF` }}
            >
              <p className="text-black font-medium text-base">Processing...</p>
            </button>
          ) : (
            <button
              className="bg-ColorsChapter2-50 w-full p-2 mt-2 rounded flex justify-center items-center gap-1"
              onClick={() => buyBoost(boosters[boosterIndex].booster_id)}
            >
              <img src={Coin} alt="Coin" className="w-[25px] h-[25px]" />
              <p className="text-black font-medium text-base">
                {abbreviateNumber(boosters[boosterIndex].price)}
              </p>
            </button>
          )
        ) : boosters[boosterIndex]?.selected ? (
          <button className="bg-ColorsChapter2-350 w-full p-2 mt-2 rounded flex justify-center items-center opacity-55">
            <p className="text-white opacity-15 font-medium text-base">Selected</p>
          </button>
        ) : (
          <button
            className="bg-ColorsChapter2-50 w-full p-2 mt-2 rounded flex justify-center items-center text-black"
            onClick={() => selectBooster(boosters[boosterIndex].booster_id)}
          >
            <p className="font-medium text-base">Select</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default JockeyShoppage;
