import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTelegram } from './hooks/useTelegram'
import Router from './router/router'
import { ErrorProvider, useError } from './services/errors/errorContext'
import ErrorSnackbar from './services/errors/errorSnackbar'

interface TelegramBackButton {
  show: () => void;
  hide: () => void;
  onClick: (callback: () => void) => void;
  offClick: (callback: () => void) => void;
}

interface TelegramWebApp {
  close: () => void;
  platform?: string;
  setHeaderColor: (color: string) => void;
  BackButton?: TelegramBackButton;
  expand?: () => void;
}

const tg: TelegramWebApp | undefined = (window as any).Telegram?.WebApp;

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useTelegram();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (tg) {
      tg.expand?.();
    }
  }, []);

  useEffect(() => {
    if (tg && tg.setHeaderColor) {
      tg.setHeaderColor("#000000");
    }
  }, []);

  useEffect(() => {
    if (!userId) return;
  }, [userId]);

  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1);
    };
    if (location.pathname === '/shop' || location.pathname === '/update-image') {
      if (tg?.BackButton) {
        tg.BackButton.show();
        tg.BackButton.onClick(handleBackButton);

        return () => {
          tg.BackButton?.offClick(handleBackButton);
          tg.BackButton?.hide();
        };
      }
    }
  }, [location.pathname]);

  return (
    <ErrorProvider>
      <AppContent />
    </ErrorProvider>
  );
}

function AppContent() {
  const { error, setError } = useError();

  return (
    <>
      <Router />
      <ErrorSnackbar error={error} onClose={() => setError('')} />
    </>
  );
}

export default App;
