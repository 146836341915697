import React, { useEffect, useRef, useState } from 'react'
import Avatar from 'react-avatar-edit'
import './cropStyle.css'

interface Props {
  src: string; 
  preview: string;
  setPreview: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const CropAvatar: React.FC<Props> = ({ src, setPreview, setFile }) => {
  const [key, setKey] = useState<number>(0);
  const avatarRef = useRef<HTMLDivElement>(null);
  
  const onCrop = (view: string | ArrayBuffer) => {
    const base64ToBlob = (base64: string, mimeType: string): Blob => {
      const byteString = atob(base64.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      return new Blob([arrayBuffer], { type: mimeType });
    };

    const base64ToFile = (base64: string, fileName: string, mimeType: string): File => {
      const blob = base64ToBlob(base64, mimeType);
      return new File([blob], fileName, { type: mimeType });
    };

    const mimeType = 'image/jpeg';
    const fileName = 'avatar.jpg';
    const file = base64ToFile(view as string, fileName, mimeType);

    setPreview(view as string);
    setFile(file);
  };

  const onClose = () => {
    setPreview('');
    setFile(null);
  };

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [src]);

  return (
    <div className='flex flex-col w-full justify-center items-center text-white'>
      <p className='text-3xl font-bold mt-3'>Choose your avatar</p>
      <div className="avatar-container mt-10" >
        <Avatar 
          key={key}
          width={250} 
          height={300} 
          labelStyle={{color: 'white', fontSize: 22}} 
          onCrop={onCrop} 
          onClose={onClose}
          label="Click me to select"
          src={src} 
          shadingColor="rgba(0, 0, 0, 2)" 
          cropColor="transparent" 
        />
      </div>
    </div>
  );
}

export default CropAvatar;
