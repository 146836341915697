type props = {
  color: string;
};
const JockeyIcon = ({ color }: props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_27_9390)">
        <g clipPath="url(#clip1_27_9390)">
          <path
            d="M20.8288 10.3707C20.7352 10.2629 20.5889 10.2182 20.4505 10.2542C19.6415 10.4675 18.8879 10.9517 18.2709 11.6541L18.0261 11.9328L17.9902 11.5634C17.9779 11.4346 17.9657 11.3043 17.9537 11.1731C17.7592 9.08559 17.5391 6.71967 15.6642 5.32989C12.6771 3.11425 11.2777 1.31061 10.6299 0.187588C10.5549 0.057608 10.4089 -0.0157827 10.2607 0.00287417C10.1117 0.0208095 9.98773 0.125639 9.94568 0.269843C9.6551 1.26433 9.34938 2.66978 9.54264 3.71776C9.69664 4.55258 10.2975 5.39287 10.8791 6.20655C11.5398 7.13064 12.1639 8.00349 12.0054 8.76069C11.9305 9.11878 11.6824 9.42554 11.2682 9.67262C10.9829 9.84259 10.6938 9.92876 10.4081 9.92886C9.84466 9.92886 9.29608 9.5817 8.86347 8.95149C8.37303 8.23717 8.07524 7.23712 8.10483 6.40395C8.10895 6.2851 8.05648 6.1712 7.9634 6.09719C7.87012 6.02308 7.74725 5.99782 7.63253 6.02885C7.00479 6.19851 6.39756 6.57413 5.92228 7.08673C5.26166 7.79909 4.91893 8.68689 4.98201 9.52285C5.05798 10.517 5.61078 11.3968 6.14544 12.248C6.61794 13 7.10653 13.7776 7.21806 14.5992C7.28804 15.1148 7.20558 15.5114 6.97325 15.7775C6.83007 15.9412 6.55331 16.1362 6.04669 16.1363C5.45915 16.1363 4.71659 15.8813 4.06019 15.4544C3.94382 15.3786 3.79477 15.3736 3.67324 15.4413C3.55182 15.509 3.47791 15.6385 3.48131 15.7776C3.5345 17.9692 4.28985 19.9201 5.66582 21.4197C7.1926 23.0837 9.35092 24 11.7432 24C12.7167 24 13.7231 23.849 14.7345 23.5512C18.0905 22.5628 20.4247 19.0518 20.0488 15.5578C19.9399 14.5466 19.998 12.8939 20.8919 10.7614C20.9471 10.6299 20.9225 10.4784 20.8288 10.3707Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_27_9390">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0.201172)"
          />
        </clipPath>
        <clipPath id="clip1_27_9390">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0.201172)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JockeyIcon;
