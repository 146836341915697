import { motion } from 'framer-motion'
import React, { useState } from 'react'
import LazyLoad from 'react-lazyload'
import { useNavigate } from 'react-router-dom'
import CamelGIF from '../images/standCamel.gif'


const HowToPlay: React.FC = () => {
    const [pageIndex, setPageIndex] = useState<number>(0);
    const navigate = useNavigate();
    
    const handleNextPage = () => {
        if (pageIndex === 2) {
            navigate('/signup');
        } else {
            setPageIndex(prevIndex => prevIndex + 1);
        }
    };

    const pages = [
        { title: 'Play Mini Game', descr: 'Join your camel on an exciting journey through the desert. Tap the screen to make your camel run and earn coins. Aim for the oasis and discover the adventure that awaits!' },
        { title: 'How to Play', descr: 'Tap the screen to make your camel run. Each tap earns you 1 coin, and picking up special objects gives you 2 coins. Keep an eye on your progress bar, speed, and total coins at the top of the screen.' },
        { title: 'Earn and Upgrade', descr: 'Use your coins to buy new camels or upgrade their speed, stamina, regeneration, and point bonuses. Share the game with friends to earn referral bonuses. Ready to dash to the oasis?' },
    ];

    return (
        <div className='text-white'>
            <div className='relative'>
                <LazyLoad height={350} offset={100}>
                    <img src={CamelGIF} alt="Camel" className='z-10 h-[350px] w-[350px] relative' />
                </LazyLoad>
                
                <div className='absolute w-[240px] h-[260px] inset-0 left-[40%] top-[10%] blur-2xl' style={{ backgroundColor: '#293B39' }} />
                <div className='absolute w-[150px] h-[220px] inset-0 top-[15%] blur-3xl opacity-55' style={{ backgroundColor: '#F8C17A' }} />
            </div>
            <motion.div
                key={pageIndex}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                layout
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className='flex flex-col mt-5 h-pc:relative h-pc:bottom-10 h-pc:mt-0 rounded-lg'
                style={{ minHeight: '180px' }}
            >
                <p className="text-xl font-bold mb-2 h-pc:text-sm">{pages[pageIndex].title}</p>
                <p className='text-ColorsChapter2-100 leading-5 h-pc:text-sm'>{pages[pageIndex].descr}</p>
            </motion.div>
            <div className="flex mt-4 h-xs:mt-2 items-center h-pc:relative h-pc:bottom-[15vh]">
                {pages.map((_, index) => (
                    <div
                        key={index}
                        className={`w-7 mx-1 rounded-full ${pageIndex === index ? 'bg-black border h-[7px]' : 'h-[2px] bg-gray-400'}`}
                    />
                ))}
            </div>

            <div className='flex justify-center h-pc:relative h-pc:bottom-[10vh]'>
                <button 
                    onClick={handleNextPage} 
                    className="bg-ColorsChapter2-50 text-black px-4 py-2 rounded-lg mt-10 h-xs:mt-2 w-full"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default HowToPlay;
