
import QRCode from 'react-qr-code'
import '../components/ui/background.css'
const Desktop = () => {
	const valueToEncode = "https://t.me/MetaCamelGame_bot"

	return (
		<>
			<div className='loadingbg' />

			<div className='flex flex-col justify-center h-screen items-center w-full'>
				<div>
					<div className='relative'>
						<div className='bg-white rounded-2xl p-3'>
							<QRCode value={valueToEncode} size={150} />
						</div>
					</div>
				</div>
				<p className='text-3xl text-white text-center font-medium mt-5 w-full'>
					Launch the app via your phone</p>
			</div>
		</>
	)
}

export default Desktop