import { useState } from 'react'
import { useNecessary } from '../../hooks/necessary'
import useApi from '../../services/api/apiHandlerService'
import { stamina } from "../../types/gameAttributes/booster"
import Battery from "../images/boostpage/battery.png"
import Water from "../images/boostpage/regeneration.png"
import Coin from "../images/coin.png"
import UpgradeBar from './jockeypage/upgradeBar'

type EnergyEmptyProps = {
  setIsWaterZeroPopupVisible: (value: boolean) => void;
  buyUpgrade: (boost_id: string) => void;
  upgrades: stamina[];
  setTimer: (value: number) => void;
  setIsTimerActive: (value: boolean) => void;
  replenishPrice: number;
  setReplishPrice: React.Dispatch<React.SetStateAction<number>>
  loadDataAsync: () => Promise<void>
};
const EnergyEmpty: React.FC<EnergyEmptyProps> = ({
  loadDataAsync,
  setIsWaterZeroPopupVisible,
  buyUpgrade,
  upgrades,
  setTimer,
  setIsTimerActive,
  replenishPrice,
  setReplishPrice
}) => {
  const handleClose = () => {
    setIsTimerActive(true);
    setIsWaterZeroPopupVisible(false);
    setTimer(3);
  };
  const api = useApi()
  const {identityId} = useNecessary()
  const [loading, setLoading] = useState<boolean>(false)

  const buyInstant = async() => {
    setLoading(true)
    const res = await api<number>({
      url: `/boost/instant_energy`,
      method: 'POST',
      headers: {
        'x-user-id': identityId
      }
    })
    await loadDataAsync()
    setIsWaterZeroPopupVisible(false)
    setLoading(false)
    if (res) setReplishPrice(res)
  }

  return (
    <>
      <div
        className="relative w-full bg-ColorsChapter2-700 text-white rounded-xl p-5"
        style={{
          clipPath:
            "polygon(0.00% 0.00%, 99.80% 0.80%, 100% 10.00%, 99.80% 92.00%, 65.00% 92.00%, 58.20% 100%, 0.00% 100%, 0.00% 10.00%)",
        }}
      >
        <div
          className="absolute w-[200px] h-[160px] inset-0 left-[35%] top-[10%] blur-[60px]"
          style={{ backgroundColor: "#293B39" }}
        />
        <div
          className="absolute w-[100px] h-[120px] inset-0 top-[20%] blur-[70px] opacity-55 left-[20%]"
          style={{ backgroundColor: "#F8C17A" }}
        />
        <div className="z-10 relative">
          <div className=" flex justify-between w-full">
            <div>
              <h2 className="mb-2 text-base font-semibold">Buy Boost</h2>
              <p className="text-km max-w-48 font-light">
                Recharge your energy instantly and jump back into the game
              </p>
            </div>
            <img className="w-14 h-14" src={Water} alt="Вода" />
          </div>
          <div className={`flex items-center gap-2 pt-2 text-2xl font-semibold mb-7`} >
            <img className="w-10 h-10" src={Coin} alt="монетка" />
            {replenishPrice}
          </div>
          <button className={`bg-ColorsChapter2-50 text-black w-full rounded-xl py-3 ${loading && 'opacity-55'}`} onClick={() => buyInstant()} disabled={loading}>
            Buy Now
          </button>
          <div className="flex gap-4 mt-4 items-end">
            <img src={Battery} alt="Батарейка" />
            <div className='flex flex-col'>
            <p className=' font-semibold'>Endurance</p>
            <p className="text-sm w-48">
              Increase your energy capacity to keep playing longer
            </p>
            </div>
          </div>
          <div>
            {upgrades.map((upgrade) => (
              <div className='flex mt-5 items-center justify-between'>
              <UpgradeBar lvl={upgrade?.lvl} index={2}/>
              {upgrade?.lvl === 15 ? (
                <button
                className="flex max-w-20 w-full text-white items-center px-3 py-1.5 bg-ColorsChapter2-200 rounded-md justify-center opacity-55"
              >
                Max
              </button>
              ) : (
                <button
                onClick={() => buyUpgrade(upgrade.id)}
                className="flex max-w-20 w-full text-black items-center px-3 py-1.5 bg-ColorsChapter2-50 rounded-md"
                >
                  <img className="w-5 h-5" src={Coin} alt="Монетка" />
                  {upgrade.price}
                </button>
              )}
              </div>
            ))}
          </div>
          <p
            onClick={() => handleClose()}
            className="text-base mt-2 ml-20 text-ColorsChapter2-50 font-semibold"
          >
            Skip
          </p>
        </div>
      </div>
      <button
        onClick={() => handleClose()}
        className="absolute py-1 px-3 border-1 border-slate-400 rounded-lg top-[10%] custom-smmm:top-[20%] bg-black right-[5%] text-white"
      >
        <span className="w-6 h-6">X</span>
      </button>
    </>
  );
};

export default EnergyEmpty;
