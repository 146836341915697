import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Light from "../components/images/boostpage/waterCamel.png"
import Coin from "../components/images/coin.png"
import CamelIcon from "../components/images/gameimage/Camel.png"
import iconCoin from "../components/images/gameimage/frameBonus.png"
import modalBack from "../components/images/gameimage/menu.png"
import PalmIcon from "../components/images/gameimage/Palm.png"
import Union from "../components/images/gameimage/Union.png"
import Footer from "../components/images/gameimage/Vector.png"
import useCamelImages from "../components/store/camelImages"
import EnergyEmpty from "../components/ui/EnergyEmpty"
import BackgroundGame from "../components/ui/gamepage/backgroundGame"
import Camel from "../components/ui/gamepage/camel"
import {
  loadCamelSkin,
  preloadImages,
} from "../components/ui/gamepage/camelSkinLoader"
import "../components/ui/gamepage/styleprogress.css"
import TapTextComponent from "../components/ui/gamepage/tap"
import "../components/ui/gamepage/tapbar.css"
import WaterProgressBar from "../components/ui/gamepage/waterProgress"
import { preloadBackground, useNecessary } from "../hooks/necessary"
import { useTelegram } from "../hooks/useTelegram"
import useApi from "../services/api/apiHandlerService"
import { stamina } from "../types/gameAttributes/booster"

interface Camel {
  progress: number;
  speed: number;
  current_coin: number;
  stamina: number;
  current_water: number;
  regeneration: number;
  type: number;
  camel: string;
}

const Gamepage: React.FC = () => {
  const [stamina, setStamina] = useState<number>(0);
  const [water, setWater] = useState<number>(2);
  const [coin, setCoin] = useState<number>(0);
  const [regeneration, setRegeneration] = useState<number>(0);
  const [progressCount, setProgressCount] = useState<number>(0);
  const { imagesLoaded, getData, proccessLoading, setProccessLoading } =
    useNecessary();
  const [modal, setModal] = useState<boolean>(false);
  const api = useApi();
  const navigate = useNavigate();
  const { tg, userId } = useTelegram();
  const location = useLocation();
  const camelId = location.state?.camelId;
  const { identityId } = useNecessary();
  const [upgrades, setUpgrades] = useState<stamina[]>([]);
  const tapRef = useRef<number>(0);
  const { setIsActive, setLastClick, setBackgroundImages } = useNecessary();
  const { setImages, setImagesStart, setImagesEnd } = useCamelImages();
  const [camelSkin, setCamelSkin] = useState<string>("");
  const [camelLoaded, setCamelLoaded] = useState<boolean>(false);
  const [isWaterZeroPopupVisible, setIsWaterZeroPopupVisible] =
    useState<boolean>(false);
  const [timer, setTimer] = useState<number>(3);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const requestTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const frameCount = 60;
  const [coinForTap, setCoinForTap] = useState<number>(1)
  const [replenishPrice, setReplishPrice] = useState<number>(0)

  const LoadBackground = async () => {
    const loadedImages = await preloadBackground(
      frameCount,
      setProccessLoading
    );
    setBackgroundImages(loadedImages);
  };

  const claimReward = async () => {
    const res = await api({
      url: `/game/claim-reward?user_id=${userId}`,
      method: "POST",
    });
    await loadDataAsync();
    getData();
    setModal(false);
  };

  useEffect(() => {
    loadDataAsync();
  }, []);

  interface data {
    current_coin: number,
    coin_per_hour: number,
    current_water: number,
    current_path: number,
    camel: string,
    replenish_energy_price: number,
    point_per_tap: number,
    speed: number,
    stamina: number,
    regeneration: number
  }

  const loadDataAsync = async () => {
    const res = await api<data>({
      url: `/game/get-info?user_id=${userId}&camel_id=${camelId}`,
      method: "GET",
    });
    if (res) {
      setStamina(res?.stamina);
      setWater(res.current_water);
      setRegeneration(res.regeneration);
      setCoin(res.current_coin);
      setCoinForTap(res?.point_per_tap)
      setProgressCount(res.current_path);
      setCamelSkin(res.camel);
      setReplishPrice(res?.replenish_energy_price)
    }
  };

  useEffect(() => {
    if (progressCount >= 100) {
      setModal(true);
    }
  }, [progressCount]);

  const setPoints = async () => {
    const res = await api({
      url: `/game/set-points?user_id=${userId}&current_path=${progressCount}&coin=${coin}&current_water=${water}`,
      method: "POST",
    });
  };

  const handleTap = () => {
    if (water > 0 && progressCount !== 100) {
      setCoin((prev) => prev + coinForTap);
      tapRef.current++;
      setProgressCount((prev) => prev + 0.15);
      setLastClick(new Date());
      setIsActive(true);
      if (requestTimeoutRef.current) {
        clearTimeout(requestTimeoutRef.current);
      }

      requestTimeoutRef.current = setTimeout(() => {
        setPoints();
        tapRef.current = 0;
      }, 200);
    }
  };

  console.log(water);
  

  useEffect(() => {
    return () => {
      if (requestTimeoutRef.current) {
        clearTimeout(requestTimeoutRef.current);
      }
    };
  }, []);

   const getUpgrade = async () => {
    if (!identityId) return;
    const res = await api<stamina[]>({
      method: "GET",
      url: `/boost/get-stamina-info`,
      headers: {
        "x-user-id": identityId,
      },
    });
    if (res) setUpgrades(res);
  }; 

  const buyUpgrade = async (boost_id: string) => {
    if (!identityId) return;
    const res = await api({
      method: "POST",
      url: `/boost/buy-camel-boost?boost_id=${boost_id}&camel_id=${camelId}`,
      headers: {
        "x-user-id": identityId,
      },
    });
    loadDataAsync()
    getUpgrade(); 
  };

  useEffect(() => {
    if (identityId) {
      getUpgrade();
    }
  }, [identityId]);

  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1);
    };

    if (tg?.BackButton) {
      tg.BackButton.show();
      tg.BackButton.onClick(handleBackButton);

      return () => {
        if (tg.BackButton) {
          tg.BackButton.offClick(handleBackButton);
          tg.BackButton.hide();
        }
      };
    }
  }, [tg?.BackButton]);

  useEffect(() => {
    setCamelLoaded(false);
    if (!camelSkin) return;
    const fetchImages = async () => {
      try {
        const resForLoop = loadCamelSkin(camelSkin, "loop");
        const resForStart = loadCamelSkin(camelSkin, "start");
        const resForEnd = loadCamelSkin(camelSkin, "end");
        await LoadBackground();
        const loadedStart = await preloadImages(0, 13, resForStart);
        const loadedImages = await preloadImages(14, 28, resForLoop);
        const loadedEnd = await preloadImages(29, 43, resForEnd);
        if (loadedStart.length && loadedImages.length && loadedEnd.length) {
          setImagesStart(loadedStart);
          setImages(loadedImages);
          setImagesEnd(loadedEnd);
          setCamelLoaded(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    fetchImages();
  }, [camelSkin]);

  useEffect(() => {
    if (water === 0) {
      setIsWaterZeroPopupVisible(true);
    }
  }, [water]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && isTimerActive) {
      setIsTimerActive(false);
      setIsWaterZeroPopupVisible(false);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isTimerActive, timer]);

  const loading = () => {
    return (
      <>
        <div className="background opacity-10 z-[99999]" />
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="w-full flex justify-center items-center">
            <div className="max-w-[350px] w-full justify-center items-center">
              <p className="text-4xl text-white font-medium text-center">
                META CAMEL
              </p>
              <p className="opacity-55 text-white text-base text-center">
                Please wait, data is loading
              </p>
              <div className="w-full bg-gray-200 bg-opacity-30 rounded-full h-4 mt-4">
                <div
                  className="h-4 rounded-full transition-all"
                  style={{
                    width: `${proccessLoading}%`,
                    background:
                      "linear-gradient(82.14deg, #3C2E1D 0%, #D4A362 98.94%)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {!camelLoaded || !imagesLoaded ? (
        loading()
      ) : (
        <>
          <TapTextComponent onTap={handleTap} coinForTap={coinForTap} water={water} />
          <div className="w-full h-[90vh] overflow-hidden relative">
            {isTimerActive && (
              <div className="fixed inset-0 flex bg-black bg-opacity-35 items-center justify-center z-50">
                <div className="p-5 rounded-lg flex flex-col justify-center items-center text-white font-bold">
                  <p className="text-4xl">{timer}</p>
                  <p className="text-3xl">Recharging...</p>
                </div>
              </div>
            )}
            {isWaterZeroPopupVisible && (
              <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 px-5 z-[99]">
                <EnergyEmpty
                  loadDataAsync={loadDataAsync}
                  setIsTimerActive={setIsTimerActive}
                  setTimer={setTimer}
                  buyUpgrade={buyUpgrade}
                  replenishPrice={replenishPrice}
                  setReplishPrice={setReplishPrice}
                  upgrades={upgrades}
                  setIsWaterZeroPopupVisible={setIsWaterZeroPopupVisible}
                />
              </div>
            )}
            <BackgroundGame />
            <div className="z-[9]">
              <Camel />
            </div>
            <div
              className="absolute top-0 w-full h-[50px]"
              style={{ background: "#101011" }}
            >
              <div className="relative">
                <img src={Union} alt="" className="mt-11 w-full" />
                <div className="absolute text-white top-1 gap-0 w-full flex justify-center items-center">
                  <img src={Coin} alt="" className="w-[30px] h-[30px]" />
                  <p className="text-lg">{+coin.toFixed(0)}</p>
                </div>
              </div>
              {/* Header */}
              <div className="absolute top-2 text-white w-full justify-center flex items-center gap-4">
                <img src={CamelIcon} className="imageCamel" alt="" />
                <div className="progress-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${progressCount}%` }}
                  >
                    <div className="progress-circle">
                      {`${Math.floor(progressCount)}%`}
                    </div>
                  </div>
                </div>
                <img src={PalmIcon} className="imagePalm" alt="" />
              </div>
            </div>
            {/* Footer */}
            <div className="fixed bottom-[60px] w-full">
              <img src={Footer} alt="" className="w-full" />
              <div
                className="absolute bottom-[25px] left-9 h-pc:left-2 h-pc:bottom-[15px] tap-bar-container p-1 rounded"
                style={{ background: "#181819", border: "1px solid #28282A" }}
              >
                <WaterProgressBar
                  tap={tapRef.current}
                  stamina={stamina}
                  regeneration={regeneration}
                  water={water}
                  setWater={setWater}
                />
              </div>
              <button
                className="flex justify-center items-center absolute bottom-[15px] right-9 h-pc:right-5 h-pc:bottom-[10px]"
                onClick={() => navigate("/camel")}
              >
                <img src={Light} alt="" className="w-[50px] h-[50px]" />
              </button>
            </div>
          </div>
          {modal && (
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 px-5 z-[99]">
              <div className="relative">
                <img src={modalBack} alt="" className="w-full" />
                <div className="absolute bottom-[40%] w-full text-center text-white text-xl font-medium">
                  <p>Reward Bonus</p>
                  <div className="relative  mt-5 w-full flex justify-center">
                    <img src={iconCoin} alt="" />
                    <div className="absolute mt-5 flex items-center">
                      <img src={Coin} alt="" />
                      <p className="font-bold">1000</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col justify-center items-center gap-3 h-pc:relative h-pc:bottom-[11vh]">
                <button
                  className="p-2 rounded-xl font-medium w-full max-w-[330px]"
                  style={{ background: "#F8C17A" }}
                  onClick={() => claimReward()}
                >
                  Play Again
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Gamepage;
