import JockeyTable from "../components/ui/jockeypage/jockeyTable"

const JockeyPage = () => {

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-[350px] w-full">
          <p className="text-white text-center mt-5 text-3xl font-bold">
            Jockeys
          </p>
          <p className="text-ColorsChapter2-500 text-sm text-center">
            Upgrades for Jockey bots
          </p>
          <div className="mt-5 h-full h-pc:px-1">
            <JockeyTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default JockeyPage;
