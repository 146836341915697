import { useNavigate } from 'react-router-dom'
import Coin from '../components/images/coin.png'
import { ReactComponent as TaskCompleteIcon } from '../components/images/taskpage/taskcompleted.svg'
import '../components/ui/background.css'
import { useNecessary } from '../hooks/necessary'
import useApi from '../services/api/apiHandlerService'

export default function DailyRewardPage() {
	const { getData, identityId, dailyReward } = useNecessary()
	const api = useApi()
	const navigate = useNavigate()
	const claimDailyReward = async () => {
		await api({
			url: `/user-data/claim-daily-reward`,
			headers: {
				'x-user-id': identityId
			},
			method: 'POST'
		})
		getData()
		navigate('/')
	}


	return (
		<>
					<div className="background opacity-10" />
					<div className="fixed w-full flex items-center justify-center z-50 text-white h-full">
						<div className="w-full flex justify-center items-center mb-[40%]">
							<div className="max-w-[350px] w-full">
								<p className="text-2xl text-center font-bold">Your Daily Rewards</p>
								<p className="text-white text-[50px] font-bold text-center">{dailyReward?.day}</p>
								<p className="text-center" style={{ color: '#B7BBC4' }}>day check-in</p>
								<div className="w-full px-2 py-2 pt-3 rounded-xl bg-ColorsChapter2-150 text-white relative border-ColorsChapter2-200 border-[2px] mt-10">
									<div className="flex gap-2 items-center">
										<div className="rounded-full border border-ColorsChapter2-200">
											<TaskCompleteIcon />
										</div>
										<p className="text-[15px] text-ColorsChapter2-500">
											Earn Extra Rewards by Completing <br /> Tasks
										</p>
									</div>
									<div
										className="mt-4 p-2 border border-ColorsChapter2-400 rounded-xl flex justify-center gap-1 items-center"
										style={{
											background:
												'linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%',
										}}
									>
										<img src={Coin} alt="Coin" />
										<p className="font-medium text-xl">+{dailyReward?.reward}</p>
									</div>
								</div>
								<p className="mt-2 text-sm text-center" style={{ color: '#B7BBC4' }}>
									Come back tomorrow to claim even more rewards!
								</p>
							</div>
						</div>
					</div>
					<button
						className="w-full max-w-[350px] text-black font-medium fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-ColorsChapter2-50 py-2 rounded-lg outline-none z-[999]"
						onClick={claimDailyReward}
					>
						Claim
					</button>
				</>
	)
}