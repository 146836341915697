type props = {
  color: string;
};
const CamelIcon = ({ color }: props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_27_9376)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.90828 2.1637C4.33288 1.73207 3.5164 2.21316 3.60847 2.93024C3.75059 4.0373 4.14102 4.63714 4.97659 4.99725C5.54746 4.53498 6.21926 4.20352 6.87706 3.87898C6.94116 3.84735 7.00514 3.81578 7.06887 3.78417L4.90828 2.1637ZM14.9807 22H14.9883C15.8567 22 16.5687 21.2935 16.5687 20.4251H16.5685V16.3172C16.5685 14.0789 14.7422 12.258 12.504 12.258C10.2657 12.258 8.44481 14.0789 8.44481 16.3172V20.4251C8.44481 21.2935 9.15129 22 10.0198 22H10.0273C10.244 22 10.4388 21.8797 10.5356 21.6859L11.6976 19.3729V17.4653L10.3064 16.0794C9.98929 15.7624 9.98929 15.2484 10.3064 14.9314C10.6234 14.6143 11.1375 14.6143 11.4544 14.9314L12.5041 15.981L13.5537 14.9314C13.8707 14.6143 14.3848 14.6143 14.7018 14.9314C15.0188 15.2484 15.0188 15.7624 14.7018 16.0794L13.3159 17.4653V19.3729L14.4724 21.6859C14.5693 21.8796 14.7641 22 14.9807 22ZM18.1111 15.3914C19.0954 15.0786 19.816 14.1563 19.816 13.0698V8.04088C19.816 7.11247 19.2947 6.27772 18.4643 5.86251C17.9394 5.60008 17.4659 5.35687 17.0331 5.13455C13.2617 3.19746 12.5771 2.84583 7.90575 5.18159C7.81379 5.22757 7.72387 5.27225 7.63605 5.3159C6.09456 6.08197 5.19765 6.52771 5.19765 8.04088V13.0698C5.19765 14.1563 5.91288 15.0786 6.89712 15.3914C7.34051 12.6967 9.68582 10.6343 12.5041 10.6343C15.3224 10.6343 17.6677 12.6967 18.1111 15.3914ZM20.0017 4.94405C20.7616 4.57689 21.12 3.98663 21.2549 2.91867C21.3447 2.20806 20.5263 1.73416 19.9541 2.16336L17.8603 3.73374C18.177 3.89651 18.4349 4.02677 18.6495 4.13516C19.415 4.52182 19.6298 4.63034 20.0017 4.94405Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_27_9376">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0.601318)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CamelIcon;
