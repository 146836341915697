import React, { useEffect, useState } from "react"

type props = {
  lvl: number;
  index:number
};

const UpgradeBar: React.FC<props> = ({ lvl, index }) => {
  const [maxLvl, setMaxLvl] = useState<number>(0)

  useEffect(() => {
    if (index === 0) {
      setMaxLvl(5)
    } else if (index === 1) {
      setMaxLvl(10)
    } else {
      setMaxLvl(15)
    }
  }, [index])
  
  
  return (
    <div className='flex gap-[5px] border-2 border-ColorsChapter2-200 p-1 px-1 rounded'>
     {[...Array(maxLvl)].map((_, i) => (
      <div
        key={i}
        className={`w-[6px] rounded-[3px] rotate-6 h-[16px] ${
          i < lvl
            ? 'bg-gradient-to-r from-[#D6FAF2] via-[#75F0D3] to-[#75F0D3]'
            : 'bg-ColorsChapter2-200'
        }`}
      />
    ))}
  </div>
  );
};

export default UpgradeBar;
