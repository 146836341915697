import { useEffect } from "react"
import Header from "../components/base/header"
import useStore from "../components/store/zustand"
import "../components/ui/background.css"
import SelectCamelHome from "../components/ui/homepage/selectCamelHome"
import { useNecessary } from "../hooks/necessary"

const Homepage = () => {
  const { avatar } = useStore();
  const imageSrc = `data:image/png;base64,${avatar}`;
  const { getData } = useNecessary();

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header />
      <div className="w-full flex justify-center items-center text-white">
        <div className={`w-full max-w-[350px] justify-center items-center`}>
          <div>
            <SelectCamelHome />
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
