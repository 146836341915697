import { useEffect, useState } from "react"

interface Props {
  tap: number;
  stamina: number;
  regeneration: number;
  water: number;
  setWater: React.Dispatch<React.SetStateAction<number>>;
}

const WaterProgressBar = ({
  tap,
  stamina,
  regeneration,
  water,
  setWater,
}: Props) => {
  const [width, setWidth] = useState((water / stamina) * 100);
  const [level, setLevel] = useState(0);

  useEffect(() => {
    if (water > 0 && stamina > 0) {
      const scaledCurrentWater = Math.min((water / stamina) * 100, 100);

      const newLevel = Math.ceil((water / stamina) * 19);

      setWidth(scaledCurrentWater);
      setLevel(newLevel);
      console.log(level);
    } else {
      setLevel(0);
    }
  }, [water, stamina]);

  useEffect(() => {
    if (tap > 0 && water > 0) {
      setWater((prevWater) => Math.max(prevWater - 3, 0));
    }
  }, [tap]);

  useEffect(() => {
    const intervalTime = 1000 - regeneration / 100;
    const interval = setInterval(() => {
      setWater((prevWater) => Math.min(prevWater + 2, stamina));
    }, intervalTime);

    return () => clearInterval(interval);
  }, [regeneration, stamina]);

  return (
    <>
      <div className="flex gap-[5px] custom-xl:gap-[7px] custom-md:gap-[7px]">
        {[...Array(19)].map((_, i) => (
          <div
            key={i}
            className={`w-[7px] rounded-[3px] rotate-6 h-[18px] ${
              i < level
                ? "bg-gradient-to-r from-[#D6FAF2] via-[#75F0D3] to-[#75F0D3]"
                : "bg-ColorsChapter2-250"
            } ${i === level ? "bg-ColorsChapter2-650 opacity-50" : ""} `}
          />
        ))}
      </div>
    </>
  );
};

export default WaterProgressBar;
