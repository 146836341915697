import { Route, Routes } from "react-router-dom";
import { NavPanelProvider } from "../hooks/necessary";
import CamelPage from "../pages/camelpage";
import CamelShoppage from "../pages/camelshoppage";
import DailyRewardPage from "../pages/dailyrewardpage";
import Desktop from "../pages/desktop";
import Friendspage from "../pages/friendspage";
import Gamepage from "../pages/gamepage";
import Homepage from "../pages/homepage";
import HowToPlayPage from "../pages/howtoplaypage";
import RegistrationPage from "../pages/registrationpage";
import Taskspage from "../pages/taskspage";
import UpdateImage from "../pages/updateimage";
import JockeyPage from "../pages/jockeypage";

const Router = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <NavPanelProvider>
              <Homepage />
            </NavPanelProvider>
          }
        />
        <Route path="/update-image" element={<UpdateImage />} />
        <Route
          path="/dailyreward"
          element={
            <NavPanelProvider>
              <DailyRewardPage />
            </NavPanelProvider>
          }
        />
        <Route path="/desktop" element={<Desktop />} />
        <Route
          path="/game"
          element={
            <NavPanelProvider>
              <Gamepage />
            </NavPanelProvider>
          }
        />
        <Route
          path="/howtoplay"
          element={
            <NavPanelProvider>
              <HowToPlayPage />
            </NavPanelProvider>
          }
        />
        <Route
          path="/tasks"
          element={
            <NavPanelProvider>
              <Taskspage />
            </NavPanelProvider>
          }
        />
        <Route
          path="/friends"
          element={
            <NavPanelProvider>
              <Friendspage />
            </NavPanelProvider>
          }
        />
        <Route
          path="/camel"
          element={
            <NavPanelProvider>
              <CamelPage />
            </NavPanelProvider>
          }
        />
        <Route
          path="/jockey"
          element={
            <NavPanelProvider>
              <JockeyPage />
            </NavPanelProvider>
          }
        />
        <Route
          path="/shop"
          element={
            <NavPanelProvider>
              <CamelShoppage />
            </NavPanelProvider>
          }
        />
        <Route path="/signup" element={<RegistrationPage />} />
      </Routes>
    </>
  );
};

export default Router;
