type props = {
  color: string;
};
const HomeIcon = ({ color }: props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.27417 0.995945C9.11005 0.526884 9.8843 0.25 10.7504 0.25C11.6164 0.25 12.3907 0.526884 13.2266 0.995945C14.0365 1.45043 14.9645 2.12145 16.1315 2.96523L17.6405 4.05633C18.5771 4.73347 19.3251 5.2743 19.8893 5.77487C20.4718 6.29173 20.9076 6.807 21.1841 7.45513C21.4611 8.10469 21.5281 8.77045 21.491 9.53808C21.4551 10.2789 21.3177 11.1726 21.1465 12.2871L20.8312 14.3387C20.5878 15.9225 20.394 17.1837 20.1093 18.1662C19.8149 19.1821 19.4002 19.9887 18.659 20.6052C17.9208 21.2192 17.0415 21.4926 15.9673 21.6231C15.6017 21.6675 15.2026 21.6964 14.767 21.7151L14.9983 17.554C15.1693 15.0923 13.2184 13 10.7496 13C8.28076 13 6.32982 15.0923 6.5009 17.554L6.7322 21.7151C6.29715 21.6963 5.8986 21.6675 5.53343 21.6231C4.45927 21.4926 3.57994 21.2192 2.84177 20.6052C2.10052 19.9887 1.68581 19.1821 1.39142 18.1662C1.10671 17.1836 0.912922 15.9225 0.669549 14.3387L0.354293 12.2872C0.183006 11.1727 0.0456449 10.2789 0.00977902 9.53808C-0.0273854 8.77045 0.0396199 8.10469 0.316664 7.45513C0.593105 6.807 1.02892 6.29173 1.61146 5.77487C2.17565 5.27429 2.92366 4.73346 3.8602 4.05632L5.36924 2.96524C6.53623 2.12145 7.46427 1.45043 8.27417 0.995945Z"
        fill={color}
      />
      <path
        d="M8.7372 21.7494C9.00205 21.75 9.27668 21.75 9.56146 21.75H11.9393C12.2235 21.75 12.4976 21.75 12.762 21.7494L13.0022 17.4277L13.0028 17.4199C13.0962 16.1122 12.0605 15 10.7496 15C9.43864 15 8.40301 16.1122 8.49641 17.4199L8.49697 17.4277L8.7372 21.7494Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;
