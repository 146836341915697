import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CropAvatar from '../components/ui/registrationpage/cropAvatar'
import { useTelegram } from '../hooks/useTelegram'
import useApi from '../services/api/apiHandlerService'


export default function UpdateImage () {
	const [src, setSrc] = useState<string>('')
	const [preview, setPreview] = useState<string>('')
	const [file, setFile] = useState<File | null>(null)
	const api = useApi()
	const {userId} = useTelegram()
	const navigate = useNavigate()
	
	const changeAvatar = async() => {
		try {
		const res = await api({
				method: 'PUT',
				url: `/auth/update-image`,
				headers: {
						'Content-Type': 'multipart/form-data',
				},
				data:{
						file: file,
						user_id: userId
				}
		});
		navigate('/')
	}catch(e) {
		console.log(e);
	}
	}
	
	
	
 	return (
		<>
		<CropAvatar src={src} preview={preview} setPreview={setPreview} setFile={setFile}/>
		<div className='w-full flex justify-center items-center absolute left-0 bottom-10 h-pc:px-2'>
			{preview ? (
				<button
				onClick={() => changeAvatar()}
				className='bg-ColorsChapter2-50  text-black px-4 py-2 rounded-lg font-medium max-w-[350px] w-full'
		>
				Set
		</button>
			) : (
				<button
				className='bg-ColorsChapter2-50  text-black px-4 py-2 rounded-lg font-medium max-w-[350px] w-full opacity-55'
				>
						Set
				</button>
			)}
		
		</div>
		</>
	)
}