import { create } from 'zustand'
import { imagesInterface } from '../models'

const useCamelImages = create<imagesInterface>((set, get) => ({
	images: [],
	imagesStart: [],
	imagesEnd: [],

  setImages: (images: HTMLImageElement[]) => {
		set(() => ({
			images
		}))
	},
	setImagesStart: (imagesStart: HTMLImageElement[]) => {
		set(() => ({
			imagesStart
		}))
	},
	setImagesEnd(imagesEnd: HTMLImageElement[]) {
		set(() => ({
			imagesEnd
		}))
	},
}))

export default useCamelImages
