import { memo, useCallback, useRef, useState } from 'react'
import { useTelegram } from '../../../hooks/useTelegram'
import '../../ui/gamepage/gamemodule.css'

interface TapText {
    text: string;
    x: number;
    y: number;
    id: number;
}

interface Props {
    onTap: () => void;
    water: number;
    coinForTap: number;
}

interface TelegramWebApp {
    HapticFeedback: {
        impactOccurred: (type: 'light' | 'medium' | 'heavy') => void;
        notificationOccurred: (type: 'error' | 'success' | 'warning') => void;
        selectionChanged: () => void;
    }
}

interface WindowWithTelegram extends Window {
    Telegram?: {
        WebApp?: TelegramWebApp;
    }
}

const TapTextComponent: React.FC<Props> = memo(({ onTap,  water, coinForTap }) => {
    const [tapTexts, setTapTexts] = useState<TapText[]>([]);
    const nextId = useRef(0);
    const { Telegram } = window as WindowWithTelegram;
    const tg = useTelegram();

    const addTapText = useCallback((text: string, x: number, y: number) => {
        const id = nextId.current;
        nextId.current += 1;

        setTapTexts((prev) => [
            ...prev,
            { text, x, y, id }
        ]);

        setTimeout(() => {
            setTapTexts((prev) => prev.filter(tapText => tapText.id !== id));
        }, 1000);
    }, []);

    const triggerHapticFeedback = useCallback(() => {
        if (navigator?.vibrate) {
            navigator.vibrate?.(50);
        }
    }, [Telegram, navigator.vibrate]);

    const handlePointerDown = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
        tg.tg.HapticFeedback.impactOccurred('light');
        if (water > 0) {
            const { clientX: x, clientY: y } = event;

            addTapText(`x${coinForTap}`, x, y);
            triggerHapticFeedback();
            onTap();
        }
    }, [water, addTapText, onTap, triggerHapticFeedback]);

    return (
        <div
            onPointerDown={handlePointerDown}
            style={{
                width: '100vw',
                height: '82vh',
                position: 'fixed',
                top: 0,
                left: 0,
                pointerEvents: 'auto',
                zIndex: 10,
                touchAction: 'none',
                userSelect: 'none'
            }}
        >
            {tapTexts.map(({ text, x, y, id }) => (
                <div
                    key={id}
                    className="tapText font-bold text-white text-2xl"
                    style={{
                        top: y - 55,
                        left: x,
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        transition: 'opacity 1s ease-out, transform 1s ease-out',
                    }}
                >
                    <div className="fadeOut">
                        {text}
                    </div>
                </div>
            ))}
        </div>
    );
});

export default TapTextComponent;
