import { Box, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { abbreviateNumber } from '../../../hooks/convertNumber'
import { useNecessary } from '../../../hooks/necessary'
import useApi from '../../../services/api/apiHandlerService'
import { booster } from '../../../types/gameAttributes/booster'
import { camel } from '../../../types/gameAttributes/camel'
import Coin from '../../images/coin.png'
type Props = {
  camels: camel[]
  boosters: booster[]
  camelIndex: number
  mode: string
  getBoosters: () => void
  getCamels: () => void
}

const UpgradeInformer: React.FC<Props> = ({ camels, camelIndex, mode, boosters, getBoosters, getCamels }) => {
  const [progressValues, setProgressValues] = useState({ grip: 0, weight: 0, acceleration: 0, max_stamins: 0 })
  const { identityId } = useNecessary()
  const getProgressBar = (completed: number) => {
    return (completed / 3) * 100
  }
  const { getData } = useNecessary()
  const [camelId, setCamelId] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingBooster, setLoadingBooster] = useState<boolean>(false)

  useEffect(() => {
    if (mode === 'camel') {
      const selectedCamel = camels[camelIndex]
      if (selectedCamel) {
        setProgressValues({
          grip: getProgressBar(selectedCamel.gip),
          weight: getProgressBar(selectedCamel.weight),
          acceleration: getProgressBar(selectedCamel.acceleration),
          max_stamins: getProgressBar(selectedCamel.max_stamins),
        })
      }
    } else {
      const selectedBooster = boosters[camelIndex]
      if (selectedBooster) {
        setProgressValues({
          grip: getProgressBar(selectedBooster.gip),
          weight: getProgressBar(selectedBooster.weight),
          acceleration: getProgressBar(selectedBooster.acceleration),
          max_stamins: getProgressBar(selectedBooster.max_stamins),
        })
      }
    }
  }, [camelIndex, camels, boosters, mode])

  useEffect(() => {
    if (camels.length > 0) {
      setCamelId(camels[camelIndex]?.camel_id)
    }
  }, [camelIndex, camels])

  const api = useApi()

  const buyCamel = async (idCamel: string) => {
    setLoading(true)
    const res = await api({
      url: `/game/camel/buy?camel_id=${idCamel}`,
      method: 'POST',
      headers: {
        'x-user-id': identityId
      }
    })
    setLoading(false)
    getData()
    getCamels()
  }

  const buyBoost = async (idBooster: string) => {
    setLoadingBooster(true)
    const res = await api({
      url: `/game/booster/buy?camel_id=${idBooster}`,
      method: 'POST',
      headers: {
        'x-user-id': identityId
      }
    })
    setLoadingBooster(false)
    getData()
    getBoosters()
  }


  const selectBooster = async (idBooster: string) => {
    const res = await api({
      url: `/game/booster/select?booster_id=${idBooster}`,
      method: 'POST',
      headers: {
        'x-user-id': identityId
      }
    })
    getBoosters()
  }

  const progressBarStyle = mode === 'camel'
    ? 'linear-gradient(82.14deg, #3C2E1D 0%, #D4A362 98.94%)'
    : 'linear-gradient(82.14deg, #06372C 0%, #5FCBB2 98.94%)'


  const navigate = useNavigate()

  return (
    <>
      <p className='text-sm pt-3'>Traditional Camel</p>
      <div className='flex flex-col w-full bg-ColorsChapter2-450 text-[12px] rounded-xl px-3 py-3 mt-3'>
        <div className='flex flex-col'>
          <div className='w-full flex justify-between'>
            <p>Grip</p>
            <p>{mode === 'camel' ? camels[camelIndex]?.gip : boosters[camelIndex]?.gip} <span>/</span> <span>3</span></p>
          </div>
          <Box sx={{
            width: '100%',
            marginTop: '5px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #202022',
            borderRadius: '24px'
          }}>
            <LinearProgress variant="determinate"
              sx={{ height: '8px', width: '97%', borderRadius: '24px', background: 'transparent', '& .MuiLinearProgress-bar': { background: progressBarStyle } }}
              value={progressValues.grip}
            />
          </Box>

          <div className='w-full flex justify-between mt-3'>
            <p>Weight</p>
            <p>{mode === 'camel' ? camels[camelIndex]?.weight : boosters[camelIndex]?.weight} <span>/</span> <span>3</span></p>
          </div>
          <Box sx={{
            width: '100%',
            marginTop: '5px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #202022',
            borderRadius: '24px'
          }}>
            <LinearProgress variant="determinate"
              sx={{ height: '8px', width: '97%', borderRadius: '24px', background: 'transparent', '& .MuiLinearProgress-bar': { background: progressBarStyle } }}
              value={progressValues.weight}
            />
          </Box>

          <div className='w-full flex justify-between mt-3'>
            <p>Acceleration</p>
            <p>{mode === 'camel' ? camels[camelIndex]?.acceleration : boosters[camelIndex]?.acceleration}<span>/</span> <span>3</span></p>
          </div>
          <Box sx={{
            width: '100%',
            marginTop: '5px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #202022',
            borderRadius: '24px'
          }}>
            <LinearProgress variant="determinate"
              sx={{ height: '8px', width: '97%', borderRadius: '24px', background: 'transparent', '& .MuiLinearProgress-bar': { background: progressBarStyle } }}
              value={progressValues.acceleration}
            />
          </Box>

          <div className='w-full flex justify-between mt-3'>
            <p>Max stamins</p>
            <p>{mode === 'camel' ? camels[camelIndex]?.max_stamins : boosters[camelIndex]?.max_stamins}<span>/</span> <span>3</span></p>
          </div>
          <Box sx={{
            width: '100%',
            marginTop: '5px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #202022',
            borderRadius: '24px'
          }}>
            <LinearProgress variant="determinate"
              sx={{ height: '8px', width: '97%', borderRadius: '24px', background: 'transparent', '& .MuiLinearProgress-bar': { background: progressBarStyle } }}
              value={progressValues.max_stamins}
            />
          </Box>

          {mode === 'camel' ? (
            camels[camelIndex]?.lock === true ?
              loading ? (
                <>
                  <button className='w-full p-2 mt-2 rounded flex justify-center items-center gap-1' style={{ background: `#FBDAAF` }}>
                    <p className='text-black font-medium text-base'>Processing</p></button>
                </>
              ) : (
                <>
                  <button className='bg-ColorsChapter2-50 w-full p-2 mt-2 rounded flex justify-center items-center gap-1' onClick={() => buyCamel(camels[camelIndex]?.camel_id)}><img src={Coin} alt="" className='w-[25px] h-[25px]' /> <p className='text-black font-medium text-base'>{abbreviateNumber(camels[camelIndex]?.price)}</p></button>
                </>
              )
              :
              <button className={`bg-myColor-600 w-full p-2 mt-2 rounded flex justify-center items-center ${!camelId ? 'opacity-55 cursor-not-allowed' : ''}`} disabled={!camelId} onClick={() => navigate('/game', { state: { camelId } })}>
                <p className='text-black font-medium text-base'>Start</p>
              </button>
          ) : (
            boosters[camelIndex]?.lock ?
              loadingBooster ? (
                <>
                  <button className='w-full p-2 mt-2 rounded flex justify-center items-center gap-1' style={{ background: `#FBDAAF` }}>
                    <p className='text-black font-medium text-base'>Processing</p></button>
                </>
              ) : (
                <button className='bg-ColorsChapter2-50 w-full p-2 mt-2 rounded flex justify-center items-center gap-1' onClick={() => buyBoost(boosters[camelIndex]?.booster_id)}><img src={Coin} alt="" className='w-[25px] h-[25px]' /> <p className='text-black font-medium text-base'>{abbreviateNumber(boosters[camelIndex]?.price)}</p></button>
              ) :
              boosters[camelIndex]?.selected ? (
                <button
                  className='bg-ColorsChapter2-350 w-full p-2 mt-2 rounded flex justify-center items-center opacity-55'
                >
                  <p className='text-white opacity-15 font-medium text-base'>Selected</p>
                </button>
              ) : (
                <button
                  className='bg-ColorsChapter2-50 w-full p-2 mt-2 rounded flex justify-center items-center text-black'
                  onClick={() => selectBooster(boosters[camelIndex]?.booster_id)}
                >
                  <p className='font-medium text-base'>Select</p>
                </button>
              )
          )
          }
        </div>
      </div>
    </>
  )
}

export default UpgradeInformer