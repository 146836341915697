import Boost from '../images/icons/Boost.svg'
import Endurance from '../images/icons/Edurance.svg'
import Passive from '../images/icons/Pasive.svg'
import PointsPerTap from '../images/icons/PointsPerTap.svg'
import Speed from '../images/icons/Speed.svg'
import Stamina from '../images/icons/Stamina.svg'
interface IconProps {
  icon: string;
}

export const GetIcon = ({ icon }: IconProps) => {
  switch (icon) {
    case 'Speed':
      return <img src={Speed} alt="Speed Icon" />;
    case 'Boost':
      return <img src={Boost} alt="Boost Icon" />;
    case 'Stamina':
      return <img src={Stamina} alt="Stamina Icon" />;
    case 'Endurance':
      return <img src={Endurance} alt="Endurance Icon" />;
    case 'Passive Points':
      return <img src={Passive} alt="Passive Icon"/>;
    case 'Points Per Tap':
      return <img src={PointsPerTap} alt="Passive Icon" />;
    default:
      return null;
  }
};
